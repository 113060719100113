
import React, { useMemo, useState } from 'react';
import { Button, Col, Descriptions, Input, notification, Row, Space, Image } from "antd";
import { EditOutlined } from '@ant-design/icons';
import { ShopifyProductImage } from '../../store/shopifyProductImagesSlice';
import DOMPurify from 'dompurify';
import EditShopifyProductImageDescriptionModal from './EditShopifyProductImageDescriptionModal';

const { Search } = Input
interface ShopifyProductImageDetailDescriptionProps {
    shopifyProductImage: ShopifyProductImage | null;
    handleDiscard: (type: "Alt") => void
}

const ShopifyProductImageDetailDescription: React.FC<ShopifyProductImageDetailDescriptionProps> = ({ shopifyProductImage, handleDiscard }) => {

    const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
    const [editKey, setEditKey] = useState<"Alt">('Alt')
    const [editValue, setEditValue] = useState<string>('')

    const handleEditClick = (editKey: "Alt", editValue: string | undefined) => {
        if (!editValue) return
        setEditKey(editKey);
        setEditValue(editValue);
        setIsModalOpen(true);
    };

    const handleModalClose = () => {
        setEditValue("");
        setIsModalOpen(false);
    }

    const descriptionItems = useMemo(() => {
        if (shopifyProductImage) {
            return [
                {
                    key: 1,
                    label: 'Alt',
                    children: shopifyProductImage.alt,
                    span: 24
                },
                {
                    key: 2,
                    label: <Space direction="horizontal" size="small" style={{ display: 'flex' }}>
                        Rec. Alt
                        <Button type="text" onClick={() => handleEditClick('Alt', shopifyProductImage.llmAlt)} style={{ marginLeft: 8 }} icon={<EditOutlined />} />
                        <Button size="small" type="link" danger onClick={() => handleDiscard("Alt")}>
                            Discard
                        </Button>
                    </Space>,
                    children: shopifyProductImage.llmAlt,
                    span: 24
                },
                {
                    key: 3,
                    label: "Reasoning",
                    span: 24,
                    children: (
                        <div
                            dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(shopifyProductImage.llmReason ?? "") }}
                        />
                    ),
                }
            ]
        }
        return []
    }, [shopifyProductImage]);

    return (
        <div>
            <Row gutter={[16, 16]} justify="center" align="top">
                {/* Image Row */}
                <Col span={24} style={{ textAlign: "center" }}>
                    <Image
                        width={200}
                        height={200}
                        src={shopifyProductImage?.src}
                        fallback="https://via.placeholder.com/150"
                        style={{ objectFit: "cover", borderRadius: 8 }}
                    />
                </Col>
                {/* Description Row */}
                <Col span={24}>
                    <Descriptions
                        bordered
                        size="small"
                        column={24}
                        items={descriptionItems}
                        labelStyle={{ width: 100, fontSize: 14 }}
                        contentStyle={{ fontSize: 14 }}
                        layout="vertical"
                    />
                </Col>
            </Row>
            <EditShopifyProductImageDescriptionModal
                open={isModalOpen}
                handleClose={handleModalClose}
                value={editValue}
                fieldToUpdate={editKey}
                shopifyProductImage={shopifyProductImage}
            />
        </div>
    )
};


export default ShopifyProductImageDetailDescription;
