import React from "react";
import { Layout, Typography, Space } from "antd";

const { Content } = Layout;
const { Title, Text } = Typography;

const ImprintPage: React.FC = () => {
    return (
        <Layout style={{ minHeight: "100vh", backgroundColor: "#f0f2f5" }}>
            <Content style={{ maxWidth: 800, margin: "50px auto", padding: "20px", background: "#fff", borderRadius: "8px", boxShadow: "0 2px 8px rgba(0, 0, 0, 0.1)" }}>
                <Title level={2} style={{ textAlign: "center", marginBottom: "20px" }}>
                    Legal Disclosure
                </Title>
                <Space direction="vertical" size="middle" style={{ width: "100%" }}>
                    <section>
                        <Title level={4}>Information according to § 5 TMG</Title>
                        <Text>
                            SEOPhenix UG (haftungsbeschränkt) <br />
                            c/o Klein AG <br />
                            Neumann-Reichardt-Str. 27-33, Haus 14 <br />
                            22041 Hamburg <br />
                            Deutschland
                        </Text>
                    </section>

                    <section>
                        <Title level={4}>Contact</Title>
                        <Text>
                            {/* TODO: Add phone number */}
                            {/* Phone: +1 (123) 456-7890 <br /> */}
                            Email: <a href="info@0seophenix.com">info@seophenix.com</a>
                        </Text>
                    </section>

                    <section>
                        <Title level={4}>Represented by</Title>
                        <Text>Sebastian Klein</Text>
                    </section>

                    <section>
                        <Title level={4}>VAT ID</Title>
                        <Text>Value Added Tax Identification Number (VAT ID): DE451264004</Text>
                    </section>

                    <section>
                        <Title level={4}>Disclaimer</Title>
                        <Text>
                            <strong>Liability for content</strong>
                            <br />
                            As a service provider, we are responsible for our own content on these pages in accordance with general laws pursuant to § 7 (1) TMG.
                            However, according to §§ 8 to 10 TMG, we are not obligated to monitor transmitted or stored third-party information or investigate circumstances
                            that indicate illegal activity.
                        </Text>
                    </section>

                    <section>
                        <Title level={4}>Copyright</Title>
                        <Text>
                            The content and works created by the site operators on these pages are subject to U.S. copyright law. Third-party contributions are marked as such.
                            Reproduction, editing, distribution, and any kind of use beyond the limits of copyright law require the written consent of the respective author or creator.
                        </Text>
                    </section>
                </Space>
            </Content>
        </Layout>
    );
};

export default ImprintPage;
