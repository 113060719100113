import { Table, TablePaginationConfig } from 'antd';
import React, { useEffect, useState } from 'react';
import { OrderByDirection } from 'firebase/firestore';
import { useAppDispatch, useAppSelector } from '../../../store/hooks';
import { db } from '../../../config/firebase-config';
import { fetchShopifyProductImages, resetShopifyProductImages, setSelectedShopifyProductImage, setShopifyProductImagePagination, ShopifyProductImage } from '../../../store/shopifyProductImagesSlice';
import ShopifyProductImageDetailModal from '../ShopifyProductImageDetailModal';
import UploadApprovedShopifyPorductImageContentModal from '../UploadApprovedShopifyPorductImageContentModal';
import { getColumns } from './columns';
import TableActions from './TableActions';
import { ShopifyShop } from '../../../store/shopifyShopSlice';
import { useTableSearch } from '../../../hooks/useTableSearch';
import { ShopifyProduct } from '../../../store/shopifyProductsSlice';


interface ShopifyProductImageTableProps {

}


const ShopifyProductImageTable: React.FC<ShopifyProductImageTableProps> = () => {
    const dispatch = useAppDispatch()

    const selectedShopifyShop = useAppSelector((state) => state.shopifyShops.selectedShopifyShop);
    const selectedShopifyProduct = useAppSelector((state) => state.shopifyProducts.selectedShopifyProduct);
    const shopifyProductImages = useAppSelector((state) => state.shopifyProductImages.shopifyProductImages);
    const selectedShopifyProductImage = useAppSelector((state) => state.shopifyProductImages.selectedShopifyProductImage);
    const pageSize = useAppSelector((state) => state.shopifyProductImages.pagination.pageSize);
    const hasMore = useAppSelector((state) => state.shopifyProductImages.pagination.hasMore);
    const lastVisibleDoc = useAppSelector((state) => state.shopifyProductImages.pagination.lastVisibleDoc);
    const activeSorter = useAppSelector((state) => state.shopifyProductImages.activeSorter);
    const activeFilters = useAppSelector((state) => state.shopifyProductImages.activeFilters);

    const [shopifyProductImageModalOpen, setShopifyProductImageModalOpen] = useState(false);
    const [shopifyUploadApprovedContentModalOpen, setShopifyUploadApprovedContentModalOpen] = useState(false);
    const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);
    const [currentShopifyShop, setCurrentShopifyShop] = useState<ShopifyShop | null>(null);
    const [currentShopifyProduct, setCurrentShopifyProduct] = useState<ShopifyProduct | null>(null);
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        if (!selectedShopifyShop) {
            dispatch(resetShopifyProductImages());
            return;
        }
        if (selectedShopifyShop.id !== currentShopifyShop?.id) {
            setCurrentShopifyShop(selectedShopifyShop);
            resetShop();
        }

    }, [selectedShopifyShop])

    // Track updates to shopifyProducts and update selectedShopify product if necessary
    useEffect(() => {
        if (shopifyProductImages && selectedShopifyProductImage) {
            const updatedSelectedShopifyPorductImage = shopifyProductImages.find((sp) => sp.id === selectedShopifyProductImage.id);
            if (updatedSelectedShopifyPorductImage) {
                dispatch(setSelectedShopifyProductImage(updatedSelectedShopifyPorductImage));
            }
        }
    }, [shopifyProductImages])


    useEffect(() => {
        if (selectedShopifyShop) {
            if (selectedShopifyProduct?.id !== currentShopifyProduct?.id) {
                setCurrentShopifyProduct(selectedShopifyProduct)
                refetchShopifyProductImages(
                    selectedShopifyShop.id,
                    activeFilters,
                    activeSorter
                )
            }
        }
    }, [selectedShopifyProduct])


    useEffect(() => {
        return () => {
            dispatch(resetShopifyProductImages())
        }
    }, [dispatch])

    const resetShop = async (activeSorter?: {
        field: string;
        order: OrderByDirection;
    } | null, activeFilters?: Record<string, any>) => {
        setIsLoading(true);
        dispatch(resetShopifyProductImages());

        // Reset scroll position to top
        const table = document.querySelector('.ant-table-body') as HTMLElement;
        if (table) table.scrollTop = 0;

        if (selectedShopifyShop) {
            console.log("activeFilters", activeFilters)
            await dispatch(
                fetchShopifyProductImages({
                    shopifyShopId: selectedShopifyShop.id,
                    pageSize,
                    lastVisibleDoc: null,
                    activeSorter: activeSorter, // Add sortBy if needed
                    activeFilters: activeFilters ? activeFilters : {}, // Add active filters if needed
                    selectedShopifyProductId: selectedShopifyProduct?.id,
                    refetch: true
                })
            );
        }
        setIsLoading(false)
    }

    const fetchMoreData = async (activeSorter?: { field: string; order: OrderByDirection } | null, activeFilters?: Record<string, any>) => {


        if (!selectedShopifyShop || isLoading || !hasMore) {
            console.log("no shop or loading or no more data")
            console.log({ selectedShopifyShop, isLoading, hasMore })
            return;
        }

        setIsLoading(true);

        await dispatch(
            fetchShopifyProductImages({
                shopifyShopId: selectedShopifyShop.id,
                pageSize,
                lastVisibleDoc,
                activeSorter: activeSorter, // Add sortBy if needed
                activeFilters: activeFilters ? activeFilters : {}, // Add active filters if needed
                selectedShopifyProductId: selectedShopifyProduct?.id,
                refetch: false
            })
        );

        setIsLoading(false);
    };

    const refetchShopifyProductImages = async (
        shopifyShopId: string,
        filters?: Record<string, any>,
        sorter?: {
            field: any;
            order: OrderByDirection;
        } | null,
    ) => {
        setIsLoading(true)
        dispatch(setShopifyProductImagePagination({
            page: 1,
            pageSize,
            lastVisibleDoc: null,
            hasMore: true
        }));
        const table = document.querySelector('.ant-table-body') as HTMLElement;
        if (table) table.scrollTop = 0;
        await dispatch(
            fetchShopifyProductImages({
                shopifyShopId: shopifyShopId,
                pageSize,
                lastVisibleDoc: null,
                activeSorter: sorter, // Add sortBy if needed
                activeFilters: filters ? filters : {}, // Add active filters if needed
                selectedShopifyProductId: selectedShopifyProduct?.id,
                refetch: true
            })
        );
        setIsLoading(false)
    }

    const handleTableChange = async (
        pagination: TablePaginationConfig,
        filters: Record<string, any>,
        sorter: any
    ) => {

        console.log("handleTableChange:", {
            filters,
            sorter
        })

        const hasFiltersChanged = JSON.stringify(activeFilters) !== JSON.stringify(filters);

        if (hasFiltersChanged || sorter?.field) {
            const order = sorter.order === "ascend" ? "asc" : "desc" as OrderByDirection;
            const tempSorter = sorter.field ? { field: sorter.field, order } : null

            console.log("filters changed")
            if (selectedShopifyShop) {
                await refetchShopifyProductImages(
                    selectedShopifyShop.id,
                    filters ? filters : {},
                    tempSorter
                )
            }
        }
    };

    const handleReloadShopifyProductImages = async () => {
        if (selectedShopifyShop) {
            await refetchShopifyProductImages(
                selectedShopifyShop.id,
                activeFilters,
                activeSorter
            )
        }
    }

    const debounce = (func: Function, wait: number) => {
        let timeout: NodeJS.Timeout;
        return (...args: any[]) => {
            clearTimeout(timeout);
            timeout = setTimeout(() => func(...args), wait);
        };
    };

    const handleScroll = ({ target }: any) => {
        const { scrollTop, scrollHeight, clientHeight } = target as HTMLElement;
        if (scrollTop + clientHeight >= scrollHeight - 50) {
            fetchMoreData(activeSorter, activeFilters);
        }
    };

    const debounceScroll = debounce(handleScroll, 200);


    // Add product tile to every shopify porduct image

    const handleShopifyProductImageModalOpen = (shopifyProductImage: ShopifyProductImage) => {
        dispatch(setSelectedShopifyProductImage(shopifyProductImage))
        setShopifyProductImageModalOpen(true)
    }

    const handleShopifyProductImageViewClose = () => {
        setShopifyProductImageModalOpen(false)
        dispatch(setSelectedShopifyProductImage(null))
    }

    const handleUploadApprovedProductImageContentModalClose = () => {
        setShopifyUploadApprovedContentModalOpen(false)

    }



    const { getColumnSearchProps } = useTableSearch<ShopifyProductImage>();

    const columns = getColumns({
        getColumnSearchProps,
        handleShopifyProductImageModalOpen
    })

    return (
        <div>
            <Table
                key={selectedShopifyShop?.id}
                style={{ marginTop: '8px' }}
                columns={columns}
                dataSource={shopifyProductImages}
                rowKey="id"
                size='small'
                bordered
                pagination={false}
                onChange={handleTableChange}
                loading={isLoading && shopifyProductImages.length === 0}
                title={() => (
                    <TableActions
                        selectedRowKeys={selectedRowKeys}
                        setShopifyUploadApprovedContentModalOpen={setShopifyUploadApprovedContentModalOpen}
                        handleReloadShopifyProductImages={handleReloadShopifyProductImages}
                    />
                )}

                locale={{
                    emptyText: selectedShopifyShop
                        ? 'No data available for the selected Shopify shop.'
                        : 'Please select a Shopify shop to display data.',
                }}
                scroll={{ x: 'max-content', y: 570, scrollToFirstRowOnChange: true }}
                onScroll={debounceScroll}
            />
            <ShopifyProductImageDetailModal
                open={shopifyProductImageModalOpen}
                handleClose={handleShopifyProductImageViewClose}
            />
            <UploadApprovedShopifyPorductImageContentModal
                open={shopifyUploadApprovedContentModalOpen}
                handleClose={handleUploadApprovedProductImageContentModalClose}
            />
        </div>
    )
}

export default ShopifyProductImageTable 
