import React, { useEffect, useState } from "react";
import { Button, Input, Row, Select, Space } from "antd";
import type { ColumnType } from "antd/es/table";
import { SearchOutlined } from "@ant-design/icons";

const { Option } = Select;

export function useSearchableFilter<T>(dataIndex: keyof T, filters: { text: string; value: string }[]) {
    // const [searchText, setSearchText] = useState("");
    const [filteredItems, setFilteredItems] = useState(filters);
    const [previousFilters, setPreviousFilters] = useState<string>("");

    useEffect(() => {
        const filtersString = JSON.stringify(filters);
        // TODO: Check if this is necessary
        if (previousFilters !== filtersString) {
            setPreviousFilters(filtersString);
            setFilteredItems(filters);
        }
    }, [filters]);

    const getColumnSearchProps = (): ColumnType<T> => ({
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
            <div style={{ padding: 8 }}>
                <Select
                    showSearch
                    value={selectedKeys.length ? (selectedKeys[0] as string | undefined) : undefined}
                    placeholder={`Select ${String(dataIndex)}`}
                    style={{ width: "100%", marginBottom: 8 }}
                    onChange={(value) => {
                        setSelectedKeys(value ? [value] : []);
                        confirm();
                    }}
                    filterOption={(input, option) =>
                        (option?.value as string ?? '').toLowerCase().includes(input.toLowerCase())
                    }
                >
                    {filteredItems.map((filter) => (
                        <Option key={filter.value} value={filter.value}>
                            {filter.text}
                        </Option>
                    ))}
                </Select>
                <Row justify="space-between" align="middle">
                    <Space>
                        <Button
                            onClick={() => confirm()}
                            size="small"
                            icon={<SearchOutlined />}
                            color="primary"
                            type="primary"
                        >
                            Search
                        </Button>
                        <Button
                            onClick={() => {
                                clearFilters && clearFilters();
                                // setSearchText("");
                                setFilteredItems(filters);
                                confirm()
                            }}
                            size="small"
                        >
                            Reset
                        </Button>

                    </Space>
                    <Space size="small">
                        <Button
                            type="link"
                            onClick={() => {
                                confirm()
                            }}
                        >
                            Close
                        </Button>
                    </Space>
                </Row>


            </div>
        ),
        filterIcon: (filtered) => <SearchOutlined style={{ color: filtered ? "#1890ff" : undefined }} />,
        onFilter: (value, record) => {
            const recordValue = record[dataIndex];

            if (Array.isArray(recordValue)) {
                // Handle array filters (e.g., shopifyTags)
                return recordValue.includes(value);
            } else if (typeof recordValue === "string") {
                // Handle string filters (e.g., productType)
                return recordValue === value;
            }

            return false;
        },
    });

    return getColumnSearchProps;
}
