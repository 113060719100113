import { Table, Skeleton } from 'antd';
import { TablePaginationConfig } from 'antd/es/table';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { fetchShopifyProducts, resetShopifyProducts, setSelectedShopifyProduct, setShopifyProductPagination, setShopifyProducts, ShopifyProduct, ShopifyProductStatus } from '../../../store/shopifyProductsSlice';
import ShopifyProductDetailModal from '../ShopifyProductDetailModal';
import { useAppDispatch, useAppSelector } from '../../../store/hooks';
import { useTableSearch } from '../../../hooks/useTableSearch';
import UploadApprovedProductContentModal from '../UploadApprovedProductContentModal';
import { analyzeShopifyProducts } from '../../../api/analyzeShopifyPorduct';
import { getColumns } from './columns';
import TableActions from './TabelActions';
import { ShopifyShop } from '../../../store/shopifyShopSlice';
import { stat } from 'fs';
import { OrderByDirection } from 'firebase/firestore';
import { useSearchableFilter } from '../../../hooks/useSearchableFilter';


interface ShopifyProductTableProps {
    openImages?: () => void
}


const ShopifyProductTable: React.FC<ShopifyProductTableProps> = ({ openImages }) => {
    const dispatch = useAppDispatch()

    const selectedShopifyShop = useAppSelector((state) => state.shopifyShops.selectedShopifyShop)
    const shopifyProducts = useAppSelector((state) => state.shopifyProducts.shopifyProducts)
    const selectedShopifyProduct = useAppSelector((state) => state.shopifyProducts.selectedShopifyProduct)

    const [shopifyProductDetailModalOpen, setShopifyProductDetailModalOpen] = useState<boolean>(false)
    const [shopifyUploadApprovedContentModalOpen, setShopifyUploadApprovedContentModalOpen] = useState<boolean>(false)
    const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);

    const [isLoading, setIsLoading] = useState(false);
    const activeFilters = useAppSelector((state) => state.shopifyProducts.activeFilters)
    const activeSorter = useAppSelector((state) => state.shopifyProducts.activeSorter)
    const pageSize = useAppSelector((state) => state.shopifyProducts.pagination.pageSize)
    const lastVisibleDoc = useAppSelector((state) => state.shopifyProducts.pagination.lastVisibleDoc)
    const hasMore = useAppSelector((state) => state.shopifyProducts.pagination.hasMore)
    const [currentShopifyShop, setCurrentShopifyShop] = useState<ShopifyShop | null>(null)

    const { getColumnSearchProps } = useTableSearch<ShopifyProduct>();

    useEffect(() => {
        if (!selectedShopifyShop) {
            dispatch(resetShopifyProducts());
            return;
        }

        if (selectedShopifyShop.id !== currentShopifyShop?.id) {
            console.warn("Shopify Shop changed");
            setCurrentShopifyShop(selectedShopifyShop);
            resetShop();
        }
    }, [selectedShopifyShop]);

    // Track updates to shopifyProducts and update selectedShopify product if necessary
    useEffect(() => {
        if (shopifyProducts && selectedShopifyProduct) {
            const updatedSelectedShopifyPorduct = shopifyProducts.find((sp) => sp.id === selectedShopifyProduct.id);
            if (updatedSelectedShopifyPorduct) {
                dispatch(setSelectedShopifyProduct(updatedSelectedShopifyPorduct));
            }
        }
    }, [shopifyProducts, dispatch])


    useEffect(() => {
        return () => {
            dispatch(resetShopifyProducts())
        }
    }, [dispatch])

    const resetShop = async (activeSorter?: {
        field: string;
        order: OrderByDirection;
    } | null, activeFilters?: Record<string, any>) => {
        setIsLoading(true);
        dispatch(resetShopifyProducts());

        // Reset scroll position to top
        const table = document.querySelector('.ant-table-body') as HTMLElement;
        if (table) table.scrollTop = 0;

        if (selectedShopifyShop) {
            console.log("activeFilters", activeFilters)
            await dispatch(
                fetchShopifyProducts({
                    shopifyShopId: selectedShopifyShop.id,
                    pageSize,
                    lastVisibleDoc: null,
                    activeSorter: activeSorter, // Add sortBy if needed
                    activeFilters: activeFilters ? activeFilters : {}, // Add active filters if needed
                    refetch: true
                })
            );
        }
        setIsLoading(false)
    }

    const fetchMoreData = async (activeSorter?: { field: string; order: OrderByDirection } | null, activeFilters?: Record<string, any>) => {


        if (!selectedShopifyShop || isLoading || !hasMore) {
            console.log("no shop or loading or no more data")
            console.log({ selectedShopifyShop, isLoading, hasMore })
            return;
        }

        setIsLoading(true);

        await dispatch(
            fetchShopifyProducts({
                shopifyShopId: selectedShopifyShop.id,
                pageSize,
                lastVisibleDoc,
                activeSorter: activeSorter, // Add sortBy if needed
                activeFilters: activeFilters ? activeFilters : {}, // Add active filters if needed
                refetch: false
            })
        );

        setIsLoading(false);
    };

    const refetchShopifyProducts = async (
        shopifyShopId: string,
        filters?: Record<string, any>,
        sorter?: {
            field: any;
            order: OrderByDirection;
        } | null,
    ) => {
        setIsLoading(true)
        dispatch(setShopifyProductPagination({
            page: 1,
            pageSize,
            lastVisibleDoc: null,
            hasMore: true
        }));
        const table = document.querySelector('.ant-table-body') as HTMLElement;
        if (table) table.scrollTop = 0;
        await dispatch(
            fetchShopifyProducts({
                shopifyShopId: shopifyShopId,
                pageSize,
                lastVisibleDoc: null,
                activeSorter: sorter, // Add sortBy if needed
                activeFilters: filters ? filters : {}, // Add active filters if needed
                refetch: true
            })
        );
        setIsLoading(false)
    }

    const handleTableChange = async (
        pagination: TablePaginationConfig,
        filters: Record<string, any>,
        sorter: any
    ) => {

        console.log("handleTableChange:", {
            filters,
            sorter
        })

        const hasFiltersChanged = JSON.stringify(activeFilters) !== JSON.stringify(filters);

        if (hasFiltersChanged || sorter?.field) {
            const order = sorter.order === "ascend" ? "asc" : "desc" as OrderByDirection;
            const tempSorter = sorter.field ? { field: sorter.field, order } : null

            console.log("filters changed")
            if (selectedShopifyShop) {
                await refetchShopifyProducts(
                    selectedShopifyShop.id,
                    filters ? filters : {},
                    tempSorter
                )
            }
        }
    };

    const handleReloadShopifyProducts = async () => {
        if (selectedShopifyShop) {
            await refetchShopifyProducts(
                selectedShopifyShop.id,
                activeFilters,
                activeSorter
            )
        }
    }

    const handleUploadApprovedProductContentModalClose = () => {
        setShopifyUploadApprovedContentModalOpen(false)
    }
    const handleShopifyProductDetailModalOpen = (shopifyProduct: ShopifyProduct) => {
        dispatch(setSelectedShopifyProduct(shopifyProduct))
        setShopifyProductDetailModalOpen(true)
    }

    const handleShopifyProductDetailViewClose = () => {
        setShopifyProductDetailModalOpen(false)
        dispatch(setSelectedShopifyProduct(null))
    }

    const handleImageCountClicked = (shopifyProduct: ShopifyProduct) => {
        dispatch(setSelectedShopifyProduct(shopifyProduct))
        if (openImages) openImages()
    }

    const handleAnalyzeShopifyProducts = async () => {
        if (selectedShopifyShop) {
            await analyzeShopifyProducts(selectedShopifyShop.id)
        }
    }

    const statusFilters = Object.values(ShopifyProductStatus).map((status) => ({
        text: status,
        value: status,
    }));


    const tagFilters = useMemo(() => {
        const uniqueTags = selectedShopifyShop?.shopifyTags ?? [];
        return uniqueTags.map((tag) => ({
            text: tag,
            value: tag,
        }));
    }, [selectedShopifyShop])

    const productTypeFilters = useMemo(() => {
        const uniqueTags = selectedShopifyShop?.shopifyProductTypes ?? [];
        console.log(uniqueTags)
        return uniqueTags.map((tag) => ({
            text: tag,
            value: tag,
        }));
    }, [selectedShopifyShop])

    const vendorFilters = useMemo(() => {
        const uniqueVendors = selectedShopifyShop?.shopifyVendors ?? [];
        return uniqueVendors.map((vendor) => ({
            text: vendor,
            value: vendor,
        }));
    }, [selectedShopifyShop])


    const hasDescriptionFilters = useMemo(() => {
        return [{
            text: 'Yes',
            value: true,
        }, {
            text: 'No',
            value: false,
        }];
    }, [])


    const productTypeFilterProps = useSearchableFilter<ShopifyProduct>("productType", productTypeFilters)();
    const tagFilterProps = useSearchableFilter<ShopifyProduct>("shopifyTags", tagFilters)();
    const vendorFilterProps = useSearchableFilter<ShopifyProduct>("vendor", vendorFilters)();

    const columns = getColumns({
        getColumnSearchProps,
        handleShopifyProductDetailModalOpen,
        handleImageCountClicked,
        tagFilterProps,
        productTypeFilterProps,
        vendorFilterProps,
        hasDescriptionFilters,
        statusFilters
    })


    const debounce = (func: Function, wait: number) => {
        let timeout: NodeJS.Timeout;
        return (...args: any[]) => {
            clearTimeout(timeout);
            timeout = setTimeout(() => func(...args), wait);
        };
    };

    const handleScroll = ({ target }: any) => {
        const { scrollTop, scrollHeight, clientHeight } = target as HTMLElement;
        if (scrollTop + clientHeight >= scrollHeight - 50) {
            fetchMoreData(activeSorter, activeFilters);
        }
    };

    // Debounce the scroll handler to prevent rapid triggering
    const debounceScroll = debounce(handleScroll, 200);


    useEffect(() => {
        console.log("shopifyProducts", shopifyProducts)
    }, [shopifyProducts])

    useEffect(() => {
        console.log("isLoading", isLoading)
    }, [isLoading])


    return (
        <div>
            <Table
                key={selectedShopifyShop?.id}
                style={{ marginTop: '8px' }}
                columns={columns}
                dataSource={shopifyProducts}
                rowKey="id"
                size='small'
                bordered
                pagination={false}
                onChange={handleTableChange}
                loading={isLoading && shopifyProducts.length === 0}
                title={() => (
                    <TableActions
                        selectedRowKeys={selectedRowKeys}
                        setShopifyUploadApprovedContentModalOpen={setShopifyUploadApprovedContentModalOpen}
                        handleAnalyzeShopifyProducts={handleAnalyzeShopifyProducts}
                        handleReloadShopifyProducts={handleReloadShopifyProducts}
                    />
                )}
                scroll={{ x: 'max-content', y: 570, scrollToFirstRowOnChange: true }}
                onScroll={debounceScroll}

            />
            <ShopifyProductDetailModal
                open={shopifyProductDetailModalOpen}
                handleClose={handleShopifyProductDetailViewClose}
            />
            <UploadApprovedProductContentModal
                open={shopifyUploadApprovedContentModalOpen}
                handleClose={handleUploadApprovedProductContentModalClose}
            />
        </div >
    )
}

export default ShopifyProductTable 
