import React from "react";
import { Tag, Tooltip } from "antd";

/**
 * Renders a limited number of tags and displays the rest in a tooltip.
 *
 * @param tags - Array of strings representing tags
 * @param maxTagsToShow - Maximum number of tags to display before showing "+X more"
 */
export const renderLimitedTags = (tags: string[], maxTagsToShow: number = 3) => {
    if (!tags || tags.length === 0) return null;

    const visibleTags = tags.slice(0, maxTagsToShow);
    const hiddenTags = tags.slice(maxTagsToShow);

    return (
        <>
            {visibleTags.map((tag) => (
                <Tag key={tag}>{tag}</Tag>
            ))}
            {hiddenTags.length > 0 && (
                <Tooltip title={hiddenTags.join(", ")}>
                    <Tag color="blue">+{hiddenTags.length} more</Tag>
                </Tooltip>
            )}
        </>
    );
};
