import { Button, Space, Table, Tag, Tooltip } from 'antd';
import { ColumnsType } from 'antd/es/table';
import React, { useEffect, useState } from 'react';
import { DeleteOutlined, LinkOutlined, StepForwardOutlined } from '@ant-design/icons';
import { getFlagEmoji } from '../../utils/languageHelpers';
import { addHttpsIfMissing } from '../../utils/urlHelpers';
import { setSelectedShopifyShop, ShopifyShop } from '../../store/shopifyShopSlice';
import { analyzeShopifyShop } from '../../api/anayzeShopifyShop';
import ShopifyShopModal from './ShopifyShopModal';
import { useAppDispatch, useAppSelector } from '../../store/hooks';
import { useNavigate } from 'react-router-dom';
import { AppRoutes } from '../../routes';


interface ShopifyShopTableProps {
}


const ShopifyShopTable: React.FC<ShopifyShopTableProps> = () => {
    const navigate = useNavigate()
    const dispatch = useAppDispatch()

    const shopifyShops = useAppSelector((state) => state.shopifyShops.shopifyShops)
    const selectedShopifyShop = useAppSelector((state) => state.shopifyShops.selectedShopifyShop)
    const [shopifyShopModalOpen, setShopifyShopModalOpen] = useState<boolean>(false)


    // Track updates to the shopifyShop list and update selectedShopifyShop if necessary
    useEffect(() => {
        if (selectedShopifyShop) {
            const updatedSelectedShopifyShop = shopifyShops.find((website) => website.id === selectedShopifyShop.id);
            if (updatedSelectedShopifyShop) {
                dispatch(setSelectedShopifyShop(updatedSelectedShopifyShop));
            }
        }
    }, [shopifyShops]);

    const handleAnalyseShop = async (id: string) => {
        try {
            await analyzeShopifyShop(id)
        } catch (error) {
            console.error(error)
        }
    }

    const handleShopifyShopModalOpen = (shopifyShop: ShopifyShop) => {
        dispatch(setSelectedShopifyShop(shopifyShop))
        setShopifyShopModalOpen(true)
    }

    const handleShopifyShopModalClose = () => {
        setShopifyShopModalOpen(false)
        dispatch(setSelectedShopifyShop(null))
    }

    const handleNavigateToAnalyzeShopifyShop = (shopifyShop: ShopifyShop) => {
        dispatch(setSelectedShopifyShop(shopifyShop))
        navigate(AppRoutes.shopifyAnalyzeShop)
    }


    const columns: ColumnsType<ShopifyShop> = [
        {
            title: 'Shopify Shop',
            dataIndex: 'shop',
            key: 'shop',
            render: (url, shopifyShop) => (
                <Tooltip title={url}>
                    <a onClick={() => handleNavigateToAnalyzeShopifyShop(shopifyShop)}>
                        {url.length > 30 ? `${url.slice(0, 30)}...` : url}
                    </a>
                </Tooltip>
            ),
            fixed: 'left'
        },
        {
            title: '',
            dataIndex: 'shop',
            key: 'shop',
            render: (url) => <Button icon={<LinkOutlined />} type='text' onClick={() => window.open(addHttpsIfMissing(url + '.myshopify.com'), '_blank')} />
        },
        {
            title: 'Status',
            dataIndex: 'status',
            key: 'status',
            render: (status) => <Tag>{status}</Tag>,
        },
        {
            title: 'Language',
            dataIndex: 'llmLanguage',
            key: 'llmLanguage',
            render: (llmLanguage) => <Tooltip title={llmLanguage}><Tag>{getFlagEmoji(llmLanguage)}</Tag></Tooltip>,
        },
        {
            title: 'Products',
            dataIndex: 'productCount',
            key: 'productCount',
        },
        {
            title: 'Created',
            dataIndex: 'createdAt',
            key: 'createdAt',
            render: (createdAt) => createdAt.toDate().toLocaleDateString(),
        },
        {
            title: 'Actions',
            key: 'actions',
            render: (text, record) => (
                <Space size="small">
                    <Tooltip title='Next'>
                        <Button icon={<StepForwardOutlined />} shape='circle' onClick={() => handleAnalyseShop(record.id)} />
                    </Tooltip>
                    <Tooltip title='delete'>
                        <Button icon={<DeleteOutlined />} shape='circle' danger onClick={() => { }} />
                    </Tooltip>
                </Space>
            ),
        },
    ];

    return (
        <div>
            <Table
                style={{ marginTop: '20px' }}
                columns={columns}
                dataSource={shopifyShops}
                rowKey="id"
                size='small'
                scroll={{ x: 'max-content' }}
            />
            <ShopifyShopModal open={shopifyShopModalOpen} handleClose={handleShopifyShopModalClose} shopifyShop={selectedShopifyShop} />
        </div>
    )
}

export default ShopifyShopTable 
