import { createSlice, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit';
import { collection, getDocs, doc, Timestamp, updateDoc, startAfter, query, limit, orderBy, where, endAt, limitToLast, endBefore, startAt, OrderByDirection, getDoc } from 'firebase/firestore';
import { db } from '../config/firebase-config';


export enum ShopifyProductStatus {
  CREATED = "created",
  ANALYZING = "analyzing",
  ANALYZED = "analyzed",
  FAILED_TO_ANALYZE = "failed to analyze",
  APPROVED = "approved",
  UPLOADED = "uploaded"
}

export interface ShopifyProduct {
  id: string;
  shopifyProductId: number;
  bodyHtml: string;
  handle: string;
  image: string;
  imageCount: number;
  price: string; // should be number
  productType: string;
  shopifyStatus: string;
  shopifyTags: string; // TODO: make this an array
  title: string;
  variantCount: number;
  vendor: string;
  shopifyCreatedAt: Timestamp;
  shopifyUpdatedAt: Timestamp;
  shopifyPublishedAt: Timestamp;
  // generated content
  llmReason?: string;
  llmBodyHtml?: string;
  llmTitle?: string;
  // meta
  status: ShopifyProductStatus;
  updatedAt: Timestamp;
  createdAt: Timestamp;
}

export interface Pagination {
  page: number;
  pageSize: number;
  lastVisibleDoc: any | null;
  hasMore: boolean
}


interface ShopifyProductState {
  shopifyProducts: ShopifyProduct[];
  selectedShopifyProduct: ShopifyProduct | null;
  status: 'idle' | 'loading' | 'succeeded' | 'failed';
  error: string | null;
  // pagination
  pagination: Pagination;
  activeFilters: Record<string, any>;
  activeSorter: { field: string; order: OrderByDirection } | null;
}

const initialState: ShopifyProductState = {
  shopifyProducts: [],
  selectedShopifyProduct: null,
  status: 'idle',
  error: null,
  pagination: {
    page: 1,
    pageSize: 20,
    lastVisibleDoc: null,
    hasMore: true
  },
  activeFilters: {},
  activeSorter: null,
};


export const updateShopifyProduct = createAsyncThunk(
  'shopifyProducts/updateShopifyProduct',
  async (updatedShopifyShop: Partial<ShopifyProduct> & { id: string, shopId: string }, { rejectWithValue }) => {
    try {
      const { id, shopId, ...updates } = updatedShopifyShop;
      const timestamp = Timestamp.now();

      // Update the document in Firestore
      await updateDoc(doc(db, 'shopifyShops', shopId, 'shopifyProducts', id), {
        ...updates,
        updatedAt: timestamp,  // Automatically update the updatedAt timestamp
      });

      return { id, ...updates, updatedAt: timestamp };
    } catch (error) {
      if (error instanceof Error) {
        return rejectWithValue(error.message);
      } else {
        return rejectWithValue('An unknown error occurred');
      }
    }
  }
);

export const refetchShopifyProduct = createAsyncThunk(
  'shopifyProducts/refetchShopifyProduct',
  async ({ shopifyShopId, productId }: { shopifyShopId: string; productId: string }, { rejectWithValue }) => {
    try {
      const docRef = doc(db, 'shopifyShops', shopifyShopId, 'shopifyProducts', productId);
      const docSnapshot = await getDoc(docRef);

      if (!docSnapshot.exists()) {
        throw new Error(`Product with ID ${productId} not found`);
      }

      return {
        id: docSnapshot.id,
        ...docSnapshot.data(),
      } as ShopifyProduct;
    } catch (error) {
      if (error instanceof Error) {
        return rejectWithValue(error.message);
      } else {
        return rejectWithValue('An unknown error occurred');
      }
    }
  }
);

export const fetchShopifyProducts = createAsyncThunk(
  'shopifyProducts/fetchShopifyShopProducts',
  async (
    {
      shopifyShopId,
      pageSize,
      lastVisibleDoc,
      activeSorter,
      activeFilters,
      refetch
    }: {
      shopifyShopId: string;
      pageSize: number;
      lastVisibleDoc: any | null;
      activeSorter?: { field: string; order: OrderByDirection } | null
      activeFilters: Record<string, any>
      refetch: boolean
    },
    { rejectWithValue }
  ) => {
    try {
      const constraints = [];
      if (activeSorter) {
        constraints.push(orderBy(activeSorter.field, activeSorter.order));
      }

      // Apply filters dynamically
      Object.entries(activeFilters).forEach(([key, value]) => {
        if (value !== null && value !== undefined) {
          if (key === 'bodyHtml' && value.length === 1) {
            if (value[0]) {
              constraints.push(where('bodyHtml', '>', ''));
            } else {
              constraints.push(where('bodyHtml', '==', null));
            }
          } else if (key === 'shopifyTags') {
            console.log("Filter by tags")
            constraints.push(where(key, 'array-contains-any', value));
          } else if (key === 'title') {
            constraints.push(
              where('title', '>=', value[0]),
              where('title', '<=', value[0] + '\uf8ff')
            );
          } else {
            constraints.push(where(key, '==', value[0]));
          }
        }
      });

      // Handle pagination based on direction
      if (lastVisibleDoc) {
        constraints.push(startAfter(lastVisibleDoc));
      }
      constraints.push(limit(pageSize));

      const queryRef = query(collection(db, 'shopifyShops', shopifyShopId, 'shopifyProducts'), ...constraints);

      const querySnapshot = await getDocs(queryRef);
      const docs = querySnapshot.docs;
      console.log("length", docs.length)
      console.log("docs", docs)

      const lastDoc = docs.length > 0 ? docs[querySnapshot.docs.length - 1] : null;

      return {
        products: querySnapshot.docs.map(doc => ({
          id: doc.id,
          ...doc.data(),
        })) as ShopifyProduct[],
        lastVisibleDoc: lastDoc,
        hasMore: querySnapshot.docs.length === pageSize,
        activeFilters: activeFilters,
        activeSorter: activeSorter ? activeSorter : null,
        reftech: refetch
      };
    } catch (error: any) {
      console.error("Error in fetchShopifyProducts:", error);
      return rejectWithValue(error.message || 'An unknown error occurred');
    }
  }
);


const shopifyProductsSlice = createSlice({
  name: 'shopifyProducts',
  initialState,
  reducers: {
    setShopifyProductPagination: (state, action: PayloadAction<Pagination>) => {
      state.pagination = action.payload;
    },
    setShopifyProducts: (state, action: PayloadAction<ShopifyProduct[]>) => {
      state.shopifyProducts = action.payload;
    },
    resetShopifyProducts: (state) => {
      state.shopifyProducts = [];
      state.pagination = { page: 1, pageSize: 20, lastVisibleDoc: null, hasMore: true };
      state.status = 'idle';
      state.error = null;
      state.activeFilters = {};
      state.activeSorter = null
      state.selectedShopifyProduct = null
    },
    setSelectedShopifyProduct: (state, action: PayloadAction<ShopifyProduct | null>) => {
      state.selectedShopifyProduct = action.payload;
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchShopifyProducts.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchShopifyProducts.fulfilled, (state, action) => {
        state.status = 'succeeded';
        // If the filters are different or the active sorter to the current selected filters is should not be appended but rewritten
        const filtersChanged = JSON.stringify(state.activeFilters) !== JSON.stringify(action.payload.activeFilters)
        const sorterChanged = JSON.stringify(state.activeSorter) !== JSON.stringify(action.payload.activeSorter)
        if (filtersChanged || sorterChanged || action.payload.reftech) {
          state.shopifyProducts = action.payload.products;
        } else {
          state.shopifyProducts = [...state.shopifyProducts, ...action.payload.products];
        }
        // state.shopifyProducts = [...state.shopifyProducts, ...action.payload.products];
        state.pagination.lastVisibleDoc = action.payload.hasMore ? action.payload.lastVisibleDoc : state.pagination.lastVisibleDoc;
        state.pagination.hasMore = action.payload.hasMore
        state.activeFilters = action.payload.activeFilters
        state.activeSorter = action.payload.activeSorter
      })
      .addCase(fetchShopifyProducts.rejected, (state, action) => {
        state.status = 'failed';
        state.error = `Failed to fetch Shopify products: ${action.error.message}`;
      })
      .addCase(updateShopifyProduct.pending, (state, action) => {
        const { id, ...updates } = action.meta.arg;
        const index = state.shopifyProducts.findIndex((product) => product.id === id);

        // Optimistically update the product in the state
        if (index !== -1) {
          state.shopifyProducts[index] = {
            ...state.shopifyProducts[index],
            ...updates,
          };
        }
      })
      .addCase(updateShopifyProduct.fulfilled, (state, action) => {
        const { id } = action.payload;
        const index = state.shopifyProducts.findIndex((product) => product.id === id);

        // Replace the product with the updated product from the server
        if (index !== -1) {
          state.shopifyProducts[index] = {
            ...state.shopifyProducts[index],
            ...action.payload,
          };
        }
      })
      .addCase(updateShopifyProduct.rejected, (state, action) => {
        const { id, ...updates } = action.meta.arg;
        const index = state.shopifyProducts.findIndex((product) => product.id === id);

        // Revert the optimistic update if the backend operation fails
        if (index !== -1) {
          state.shopifyProducts[index] = {
            ...state.shopifyProducts[index],
            ...updates,
            // status: 'failed to update', // Add a flag to indicate failure
          };
        }

        state.error = `Failed to update Shopify product: ${action.error.message}`;
      })
      .addCase(refetchShopifyProduct.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(refetchShopifyProduct.fulfilled, (state, action) => {
        const updatedProduct = action.payload;
        const index = state.shopifyProducts.findIndex((product) => product.id === updatedProduct.id);

        // Update the product in the state
        if (index !== -1) {
          state.shopifyProducts[index] = updatedProduct;
        }

        state.status = 'succeeded';
      })
      .addCase(refetchShopifyProduct.rejected, (state, action) => {
        state.status = 'failed';
        state.error = `Failed to refetch product: ${action.error.message}`;
      });

  },
});

export const { setShopifyProducts, setSelectedShopifyProduct, setShopifyProductPagination, resetShopifyProducts } = shopifyProductsSlice.actions;
export default shopifyProductsSlice.reducer;